import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import EventForm from "./EventForm";
import Events from "./Events";
import EditEventForm from "./EditEventForm";
import NavBar from "./NavBar";
import HomePage from "./HomePage";
import LoginPage from "./LoginPage";
import SignUp from "./SignUp";
import { LoadScript } from "@react-google-maps/api";
import { AuthProvider } from "./AuthContext";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicies from "./PrivacyPolicies";
import Footer from "./Footer";
import AboutPage from './AboutPage';
import ArtistService from './ArtistService';
import OrganizerService from './OrganizerService';
import VenueService from './VenueService';


function App() {
  return (
    <AuthProvider>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={["places"]}
      >
        <div className="app">
          <Router>
            <NavBar />
            <Routes>
              <Route path="/" exact element={<HomePage />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/privacy-policy" element={<PrivacyPolicies />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/event-form" element={<EventForm />} />
              <Route path="/events" element={<Events />} />
              <Route path="/signup" element={<SignUp />} />
              <Route
                path="/events/edit-event/:id"
                element={<EditEventForm />}
              />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/artists" element={<ArtistService />} />
              <Route path="/organizer" element={<OrganizerService />} />
              <Route path="/venue" element={<VenueService />} />
            </Routes>
            <Footer />
          </Router>
        </div>
      </LoadScript>
    </AuthProvider>
  );
}

export default App;

import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function ArtistService() {
  return (
    <Container 
      component="main" 
      maxWidth="lg"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '100px',
        paddingBottom: '100px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
        }}
      >
        <Typography
          component="h1"
          variant="h2"
          fontFamily={"passion one"}
          fontWeight={100}
          sx={{
            color: "var(--secondary-color)",
            textAlign: 'center',
            marginBottom: '2rem',
          }}
        >
          ARTISTS ON NUUN
        </Typography>

        <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
          <Typography 
            variant="body1" 
            paragraph
            sx={{ 
              fontSize: '1.1rem',
              lineHeight: 1.8,
              marginBottom: '1.5rem',
            }}
          >
            Connect with your audience like never before. NUUN provides artists with powerful tools to manage their events, and grow their presence.
          </Typography>

          <Typography variant="h5" fontFamily={"passion one"} gutterBottom>
            Key Features for Artists:
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Direct Event Management and Scheduling
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
              Effortlessly create, manage, and schedule your events all in one place.
              </Typography>
            </div>

            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Fan Engagement Tools
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
              Connect with your audience and keep them engaged like never before.
              </Typography>
            </div>

            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Performance Analytics and Insights
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
              Gain valuable insights into your performance and audience trends.
              </Typography>
            </div>

            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Seamless Ticket Management
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
              Handle ticket sales and tracking with ease and efficiency.
              </Typography>
            </div>

            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Promotional Tools and Marketing Features
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
              Amplify your reach with built-in tools to promote your events and grow your fan base.
              </Typography>
            </div>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default ArtistService;

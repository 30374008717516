import React from 'react';
import { Container, Typography, Box } from '@mui/material';
// import ServicesCards from './ServicesCards';


function AboutPage() {
  return (
    <Container 
      component="main" 
      maxWidth="lg"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '100px',
        paddingBottom: '100px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
          marginBottom: '2rem',
        }}
      >
        <Typography
          component="h1"
          variant="h3"
          fontFamily={"passion one"}
          sx={{
            color: "var(--secondary-color)",
            textAlign: 'center',
            marginBottom: '1rem',

          }}
        >
         THIS IS NUUN
        </Typography>

        <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
          <Typography 
            variant="body1" 
            paragraph
            sx={{ 
              fontSize: '1.1rem',
              lineHeight: 1.8,
              marginBottom: '1.5rem',
            }}
          >
            We never want you to feel the disappointment of discovering an amazing event—especially one featuring your favorite artist—only to realize it's already sold out. That's why we created NUUN—a platform designed to help you experience more of the events you love, hassle-free.            <br /> <br />
           
          </Typography>
          <h4 className='about-subhead'>STAY CONNECTED</h4> <br />
          <Typography 
            variant="body1" 
            paragraph
            sx={{ 
              fontSize: '1.1rem',
              lineHeight: 1.8,
              marginBottom: '1.5rem',
            }}
          >
           We aim to create a dynamic platform that empowers event-goers, artists, organizers, and venues to connect, share, and experience the richness of the Middle East and North Africa heritage, making every moment meaningful and memorable.
           
          </Typography>
          <h4 className='about-subhead'>FROM CONCERTS TO COMMUNITY</h4> <br />
          <Typography 
            variant="body1"
            paragraph
            sx={{ 
              fontSize: '1.1rem',
              lineHeight: 1.8,
              marginBottom: '1.5rem',
            }}
          >
            Whether it's an electrifying concert, a buzzing club night, a massive festival, or an intimate comedy show, live events are where we create lasting memories, discover our communities, and explore the unseen corners of our cities.
          </Typography>
          <h4 className='about-subhead'>WHY NUUN?</h4> <br />
          <Typography 
            variant="body1"
            paragraph
            sx={{ 
              fontSize: '1.1rem',
              lineHeight: 1.8,
              marginBottom: '1.5rem',
            }}
          >
            NUUN is the one and only Arab event app connecting all Middle Easterns to the Arab entertainment world. We've made finding events effortless—no more endless scrolling on social media or missing out on exciting happenings. From searching events by location, artist, or venue to reserving tickets seamlessly, NUUN is your go-to platform for discovering what's happening around you anytime, anywhere.
            </Typography>
            <h4 className='about-subhead'>OUR MISSION</h4> <br />
          <Typography 
            variant="body1"
            paragraph
            sx={{ 
              fontSize: '1.1rem',
              lineHeight: 1.8,
              marginBottom: '1.5rem',
            }}
          >
            NUUN empowers event-goers, artists, organizers, and venues to connect, share, and experience the richness of middle eastern and north african culture. Together, we make every moment meaningful and memorable.            </Typography>
        </Box>
      </Box>
      {/* <ServicesCards /> */}
    </Container>
  );
}

export default AboutPage; 
import React, { useState, useRef} from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Autocomplete,
} from "@mui/material";

import { db } from "./firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { GeoPoint } from "firebase/firestore";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";

function EventForm() {
  const [artist, setArtist] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState(null);
  const [organizer, setOrganizer] = useState("");
  const [price, setPrice] = useState("");
  const [venue, setVenue] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [contact, setContact] = useState("");
  const [notes, setNotes] = useState("");
  const inputRef = useRef();
  const fileInputRef = useRef();
  const [existingEvents, setExistingEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setLocation(
        new GeoPoint(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        )
      );
    
      // Extract city and state from the place object
      let city, state;
      place.address_components.forEach((component) => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.short_name;
        }
      });
      setCity(`${city}, ${state}`);
      setVenue(place.name);
      setAddress(place.formatted_address.split(",")[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!image) {
      console.log("No file selected");
      return;
    }

    try {
      // Get file extension from original filename
      const fileExtension = image.name.split('.').pop();
      
      // Format the date (assuming date is in YYYY-MM-DD format)
      const formattedDate = date.replace(/-/g, ''); // Removes hyphens from date
      
      // Create custom filename using event date and artist name
      const customFileName = `${artist.replace(/\s+/g, '-')}_${formattedDate}.${fileExtension}`;
      
      const storage = getStorage();
      const storageRef = ref(storage, `images/${customFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.error("Error uploading image:", error);
          alert("Error uploading image. Please try again.");
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            const [year, month, day] = date.split("-");
            const [hours, minutes] = time.split(":");
            const eventDate = new Date(year, month - 1, day, hours, minutes);
            const timestamp = Timestamp.fromDate(eventDate);

            const eventData = {
              artist,
              title,
              image: downloadURL,
              address,
              city,
              category,
              location,
              organizer,
              price,
              venue,
              date: timestamp,
              contact,
              notes,
            };

            const eventsRef = collection(db, "events1");
            await addDoc(eventsRef, eventData);

            // Clear all form fields
            setArtist("");
            setTitle("");
            setImage(null);
            setAddress("");
            setLocation(null);
            setCity("");
            setCategory("");
            setOrganizer("");
            setPrice("");
            setVenue("");
            setDate("");
            setTime("");
            setContact("");
            setNotes("");

            // Clear file input
            if (fileInputRef.current) {
              const inputElement = fileInputRef.current.querySelector("input");
              if (inputElement) {
                inputElement.value = "";
              }
            }

            alert("Event added successfully!");
          } catch (error) {
            console.error("Error adding event:", error);
            alert("Error adding event. Please try again.");
          }
        }
      );
    } catch (error) {
      console.error("Error in form submission:", error);
      alert("Error submitting form. Please try again.");
    }
  };

  // Function to search for existing events
  const searchExistingEvents = async (searchTerm) => {
    if (!searchTerm || searchTerm.length < 2) {
      setExistingEvents([]);
      return;
    }

    setLoading(true);
    try {
      const eventsRef = collection(db, "events1");
      const q = query(
        eventsRef,
        where("artist", ">=", searchTerm),
        where("artist", "<=", searchTerm + "\uf8ff")
      );

      const querySnapshot = await getDocs(q);
      const events = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        displayText: `${doc.data().artist} - ${doc.data().city} - ${new Date(doc.data().date.seconds * 1000).toLocaleDateString()}`
      }));

      setExistingEvents(events);
    } catch (error) {
      console.error("Error searching events:", error);
    }
    setLoading(false);
  };

  return (
    <>
      <Typography
        align="center"
        sx={{
          fontFamily: "passion one",
          color: "var(--secondary-color)",
          marginTop: "18vh",
          fontSize: "20px",
        }}
      >
        EVENT SUBMISSION FORM
      </Typography>
      <form onSubmit={handleSubmit} className="event-form">
        <div style={{ display: "flex", gap: "20px" }}>
          <Autocomplete
            fullWidth
            freeSolo
            options={existingEvents}
            getOptionLabel={(option) => 
              typeof option === 'string' ? option : option.displayText
            }
            loading={loading}
            onInputChange={(event, newInputValue) => {
              setArtist(newInputValue);
              searchExistingEvents(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Artist"
                variant="outlined"
                value={artist}
                onChange={(e) => setArtist(e.target.value)}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} style={{ color: 'red' }}>
                {option.displayText}
              </li>
            )}
          />
          <TextField
            label="Title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
          />
        </div>

        <TextField
          type="file"
          ref={fileInputRef}
          onChange={handleImageChange}
          fullWidth
        />

        <div style={{ display: "flex", gap: "20px" }}>
          <TextField
            label="Organizer"
            type="text"
            value={organizer}
            onChange={(e) => setOrganizer(e.target.value)}
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel id="event-category">Category</InputLabel>
            <Select
              labelId="event-category"
              label="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value="concert">Concert</MenuItem>
              <MenuItem value="art">Art</MenuItem>
              <MenuItem value="entertainment">Entertainment</MenuItem>
              <MenuItem value="show">Show</MenuItem>
              <MenuItem value="comedy">Comedy</MenuItem>
              <MenuItem value="souk">Souk</MenuItem>
              <MenuItem value="festival">Festival</MenuItem>
            </Select>
          </FormControl>
        </div>

        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handlePlaceChanged}
        >
          <TextField
            label="Venue"
            type="text"
            value={venue}
            onChange={(e) => setVenue(e.target.value)}
            fullWidth
          />
        </StandaloneSearchBox>

        <div style={{ display: "flex", gap: "20px" }}>
          <TextField
            label="Starting price"
            type="text"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            fullWidth
          />
          <TextField
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            fullWidth
          />
          <TextField
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            fullWidth
          />
        </div>

        <TextField
          label="Reservation"
          type="text"
          value={contact}
          onChange={(e) => setContact(e.target.value)}
          fullWidth
        />

        <TextField
          label="Description"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          multiline
          rows={4}
          fullWidth
        />
        <Button type="submit" variant="contained" className="form-buttons">
          ADD EVENT
        </Button>
      </form>
    </>
  );
}
export default EventForm;

import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { auth, db, createUserWithEmailAndPassword } from "./firebase"; // Make sure to import your Firebase configuration
import { collection, addDoc } from "firebase/firestore";

function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [phone, setPhone] = useState("");
  const [profileType, setProfileType] = useState("");
  const [instagramHandle, setInstagramHandle] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError("");

    // Basic validation
    if (
      !name ||
      !email ||
      !password ||
      !businessName ||
      !phone ||
      !profileType
    ) {
      setError("All fields are required.");
      return;
    }

    // Check if passwords match
    if (password !== verifyPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      // Create user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Store additional partner information in Firestore
      await addDoc(collection(db, "partners"), {
        uid: user.uid,
        name,
        email,
        businessName,
        phone,
        profileType,
        instagramHandle,
        facebookLink,
        status: "pending", // Set status to pending
        createdAt: new Date(),
      });

   // Show alert message
   window.alert("Registration successful! Please wait for admin approval.");
      navigate("/"); // Redirect to home or another page after successful sign-up
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "150vh",
        padding: "0 5vh",
      }}
    >
      <Typography
         component="h1"
         variant="h3"
         fontFamily={"passion one"}
         sx={{
           color: "var(--secondary-color)",
           textAlign: 'center',
           marginBottom: '1rem',
        }}
      >
        BECOME A PARTNER
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <form
        onSubmit={handleSignUp}
        style={{ width: "100%", maxWidth: "400px" }}
      >
        <FormControl component="fieldset" sx={{ marginTop: "20px" }}>
          <FormLabel component="legend">Select what fits your role</FormLabel>
          <RadioGroup
            row
            value={profileType}
            onChange={(e) => setProfileType(e.target.value)}
          >
            <FormControlLabel
              value="Artist"
              control={<Radio />}
              label="Artist"
            />
            <FormControlLabel value="Venue" control={<Radio />} label="Venue" />
            <FormControlLabel
              value="Organizer"
              control={<Radio />}
              label="Organizer"
            />
          </RadioGroup>
        </FormControl>
        <TextField
          label="Business Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
        />
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Phone Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          fullWidth
          margin="normal"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          label="Verify Password"
          variant="outlined"
          fullWidth
          margin="normal"
          type="password"
          value={verifyPassword}
          onChange={(e) => setVerifyPassword(e.target.value)}
        />
        <TextField
          label="Instagram Profile (optional)"
          variant="outlined"
          fullWidth
          margin="normal"
          value={instagramHandle}
          onChange={(e) => setInstagramHandle(e.target.value)}
        />
        <TextField
          label="Facebook Profile (optional)"
          variant="outlined"
          fullWidth
          margin="normal"
          value={facebookLink}
          onChange={(e) => setFacebookLink(e.target.value)}
        />

        <Button
          type="submit"
          fullWidth
          sx={{
            marginTop: "20px",
            backgroundColor: "var(--primary-color)",
            color: "var(--secondary-color)",
            fontFamily: "passion one",
            fontSize: "1.2rem",
            ":hover": {
              backgroundColor: "var(--primary-color)",
              color: "var(--secondary-color)",
            },
            
          }}
        >
          Register
        </Button>
      </form>
      <Typography variant="body2" sx={{ marginTop: "20px" }}>
        Already have an account? <a href="/login">Log in</a>
      </Typography>
    </Box>
  );
}

export default SignUp;

import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function OrganizerService() {
  return (
    <Container 
      component="main" 
      maxWidth="lg"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '100px',
        paddingBottom: '100px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
        }}
      >
        <Typography
          component="h1"
          variant="h2"
          fontFamily={"passion one"}
          fontWeight={100}
          sx={{
            color: "var(--secondary-color)",
            textAlign: 'center',
            marginBottom: '2rem',
          }}
        >
          ORGANIZERS ON NUUN
        </Typography>

        <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
          <Typography 
            variant="body1" 
            paragraph
            sx={{ 
              fontSize: '1.1rem',
              lineHeight: 1.8,
              marginBottom: '1.5rem',
            }}
          >
            Create and manage successful events with NUUN's comprehensive organizer tools. From planning to execution, we provide everything you need to make your events stand out.
          </Typography>

          <Typography variant="h5" fontFamily={"passion one"} gutterBottom>
            Key Features for Organizers:
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Complete Event Management Platform
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                Access all the tools you need to plan and execute successful events. 
                From initial concept to final execution, our platform provides comprehensive 
                support for every aspect of event management.
              </Typography>
            </div>

            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Ticket Sales and Management
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                Handle ticket sales efficiently with our integrated ticketing system. 
                Monitor sales in real-time, manage different ticket tiers, and track 
                attendance with ease.
              </Typography>
            </div>

            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Attendee Communication Tools
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                Keep your audience informed and engaged with powerful communication tools. 
                Send updates, gather feedback, and maintain clear channels of communication 
                with all event participants.
              </Typography>
            </div>

            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Real-time Analytics and Reporting
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                Make informed decisions with comprehensive event analytics. Track ticket 
                sales, monitor engagement metrics, and generate detailed reports to measure 
                your event's success.
              </Typography>
            </div>

            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Marketing and Promotion Tools
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                Maximize your event's reach with integrated marketing tools. Create targeted 
                promotional campaigns, leverage social media integration, and attract the 
                right audience for your events.
              </Typography>
            </div>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default OrganizerService;

import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "./firebase";
import { doc, getDoc, updateDoc, Timestamp } from "firebase/firestore";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { GeoPoint } from "firebase/firestore";

function EditEventForm() {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [newImage, setNewImage] = useState(null);
  const inputRef = useRef();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewImage(file);
      console.log("File selected:", file);
    }
  };

  useEffect(() => {
    const fetchEvent = async () => {
      const eventDoc = doc(db, "events1", id);
      const eventSnapshot = await getDoc(eventDoc);
      if (eventSnapshot.exists()) {
        const data = eventSnapshot.data();
        const eventDate = data.date?.toDate();

        if (eventDate) {
          const offset = eventDate.getTimezoneOffset();
          const adjustedDate = new Date(
            eventDate.getTime() - offset * 60 * 1000
          );

          setEvent({
            ...data,
            id: eventSnapshot.id,
            date: adjustedDate.toISOString().split("T")[0],
            time: eventDate.toTimeString().slice(0, 5),
          });
        }
      }
    };

    fetchEvent();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let imageUrl = event.image;

      if (newImage) {
        const fileExtension = newImage.name.split('.').pop();
        
        const formattedDate = event.date.replace(/-/g, '');
        
        const customFileName = `${event.artist.replace(/\s+/g, '-')}_${formattedDate}.${fileExtension}`;
        
        const storage = getStorage();
        const storageRef = ref(storage, `images/${customFileName}`);
        const uploadTask = uploadBytesResumable(storageRef, newImage);

        const snapshot = await uploadTask;
        imageUrl = await getDownloadURL(snapshot.ref);
      }

      const eventDoc = doc(db, "events1", id);
      const { id: docId, ...updateData } = event;

      let eventDate;
      if (typeof event.date === "string") {
        const [year, month, day] = event.date.split("-");
        const [hours, minutes] = event.time.split(":");
        eventDate = new Date(year, month - 1, day, hours, minutes);
      } else {
        eventDate = new Date(event.date);
        if (event.time) {
          const [hours, minutes] = event.time.split(":");
          eventDate.setHours(parseInt(hours), parseInt(minutes));
        }
      }

      const updatedEvent = {
        ...updateData,
        date: Timestamp.fromDate(eventDate),
        image: imageUrl,
      };

      await updateDoc(eventDoc, updatedEvent);

      setEvent({
        artist: "",
        title: "",
        organizer: "",
        category: "",
        venue: "",
        price: "",
        date: "",
        time: "",
        contact: "",
        notes: "",
        id: id,
      });
      setNewImage(null);

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      alert("Event updated successfully!");
      navigate("/events");
    } catch (error) {
      console.error("Error updating event: ", error);
      alert("Error updating event. Please try again.");
    }
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      const newLocation = new GeoPoint(
        place.geometry.location.lat(),
        place.geometry.location.lng()
      );
      
      let city, state;
      place.address_components.forEach((component) => {
        if (component.types.includes("locality") || component.types.includes("sublocality")) {
          city = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.short_name;
        }
      });
      
      if (!city) {
        place.address_components.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            city = component.long_name;
          }
        });
      }

      if (city && state) {
        setEvent({
          ...event,
          location: newLocation,
          city: `${city}, ${state}`,
          venue: place.name,
          address: place.formatted_address.split(",")[0]
        });
      }
    }
  };

  if (!event) return <div>Loading...</div>;
  return (
    <>
      <Typography
        align="center"
        sx={{
          fontFamily: "passion one",
          color: "var(--secondary-color)",
          marginTop: "15vh",
          fontSize: "20px",
        }}
      >
        UPDATE EVENT FORM
      </Typography>
      {event.image && (
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <img
          src={event.image}
          alt="Event"
          style={{ width: '200px', height: 'auto' }} // Adjust size as needed
        />
      </div>
    )}
      <form onSubmit={handleSubmit} className="event-form">
        <div style={{ display: "flex", gap: "20px" }}>
          <TextField
            label="Artist"
            type="text"
            value={event.artist}
            onChange={(e) => setEvent({ ...event, artist: e.target.value })}
            fullWidth
          />
          <TextField
            label="Title"
            type="text"
            value={event.title}
            onChange={(e) => setEvent({ ...event, title: e.target.value })}
            fullWidth
          />
        </div>

        <TextField
          type="file"
          ref={fileInputRef}
          onChange={handleImageChange}
          fullWidth
        />

        <div style={{ display: "flex", gap: "20px" }}>
          <TextField
            label="Organizer"
            type="text"
            value={event.organizer}
            onChange={(e) => setEvent({ ...event, organizer: e.target.value })}
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel id="event-category">Category</InputLabel>
            <Select
              labelId="event-category"
              label="Category"
              value={event.category}
              onChange={(e) => setEvent({ ...event, category: e.target.value })}
            >
              <MenuItem value="concert">Concert</MenuItem>
              <MenuItem value="art">Art</MenuItem>
              <MenuItem value="entertainment">Entertainment</MenuItem>
              <MenuItem value="show">Show</MenuItem>
            </Select>
          </FormControl>
        </div>

        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handlePlaceChanged}
        >
          <TextField
            label="Venue"
            type="text"
            value={event.venue}
            onChange={(e) => setEvent({ ...event, venue: e.target.value })}
            fullWidth
          />
        </StandaloneSearchBox>

        <div style={{ display: "flex", gap: "20px" }}>
          <TextField
            label="Starting price"
            type="text"
            value={event.price}
            onChange={(e) => setEvent({ ...event, price: e.target.value })}
            fullWidth
          />
          <TextField
            type="date"
            value={event.date}
            onChange={(e) => setEvent({ ...event, date: e.target.value })}
            fullWidth
          />
          <TextField
            type="time"
            value={event.time}
            onChange={(e) => setEvent({ ...event, time: e.target.value })}
            fullWidth
          />
        </div>

        <TextField
          label="Contact"
          type="text"
          value={event.contact}
          onChange={(e) => setEvent({ ...event, contact: e.target.value })}
          fullWidth
        />

        <TextField
          label="Notes"
          value={event.notes}
          onChange={(e) => setEvent({ ...event, notes: e.target.value })}
          multiline
          rows={4}
          fullWidth
        />
        <Button type="submit" variant="contained" className="form-buttons">
          UPDATE EVENT
        </Button>
      </form>
    </>
  );
}

export default EditEventForm;

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField } from "@mui/material";
import { db } from "./firebase";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {getStorage, ref, deleteObject} from "firebase/storage";
function Events() {
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, "events1");
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsList = eventsSnapshot.docs.map((doc, index) => {
        const data = doc.data();
        const date = data.date?.toDate();

        return {
          id: index + 1,
          title: data.title,
          artist: data.artist,
          city: data.city,
          dateObj: date || new Date(0),
          date: date ? date.toLocaleString() : "No date",
          category: data.category,
          docId: doc.id,
          image: data.image,
        };
      });
      setEvents(eventsList);
      setFilteredEvents(eventsList);
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    const filtered = events.filter((event) => {
      return Object.values(event).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredEvents(filtered);
  }, [searchTerm, events]);

  const handleEdit = (event) => {
    navigate(`/events/edit-event/${event.docId}`);
  };

  const handleDelete = async (event) => {
    // Handle the delete action
    console.log(`Deleting event: ${event.docId}`);

  if (!event.image) {
    console.error("No image found for this event.");
    return; // Exit the function if there is no image
  }
  // Get the storage reference
  const storage = getStorage();
  const imagePath = event.image.split('/o/')[1].split('?')[0];
  const imageRef = ref(storage,decodeURIComponent(imagePath)); 

  try {
    // Delete the image from storage
    await deleteObject(imageRef);
    console.log("Image deleted successfully");
  } catch (error) {
    console.error("Error deleting image:", error);
  }



    const eventDoc = doc(db, "events1", event.docId);
    await deleteDoc(eventDoc);
    setEvents(events.filter((e) => e.docId !== event.docId));
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {field: "image", headerName: "Image", width: 150, renderCell: (params) => (
      <img
        src={params.value}
        alt="Event"
        style={{ width: '50%', height: 'auto' }}
      />
    ), },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      sortComparator: (v1, v2, params1, params2) =>
        params1.api.getCellValue(params1.id, "dateObj") -
        params2.api.getCellValue(params2.id, "dateObj"),
    },
    { field: "artist", headerName: "Artist", width: 150 },
    { field: "title", headerName: "Title", width: 150 },
    { field: "city", headerName: "City", width: 150 },
    { field: "category", headerName: "Category", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <button
            style={{ marginRight: "10px", padding: "5px" }}
            onClick={() => handleEdit(params.row)}
          >
            Edit
          </button>
          <button
            style={{ padding: "5px" }}
            onClick={() => handleDelete(params.row)}
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          marginTop: '20vh',
          minHeight: '90vh',
          width: '100%',
          maxWidth: '1200px',
          padding: '50px',
          paddingBottom: '100px',
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <TextField
          label="Search Events"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ 
            maxWidth: "100", 
            marginBottom: "20px", 
            width: "100%" 
          }}
        />
        <div style={{ flex: 1 }}>
          <DataGrid
            rows={filteredEvents}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            sx={{
              minHeight: "500px",
              marginBottom: "50px",
            }}
          />
        </div>
      </div>
    </>
  );
}

export default Events;

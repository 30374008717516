import { Link } from "react-router-dom";

import { Box } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faTiktok, faXTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <footer className="footer">
      <hr className="footer-divider"/>
      
      <Box className="footer-items">
        {/* Left side - Copyright */}
        <p className="footer-copyright">
          &copy; {new Date().getFullYear()} NUUN All Rights Reserved
        </p>

        {/* Right side - Social Media and Links */}
        <Box className="footer-right-content">
          <Box className='footer-link'>
            <Link to="/terms-of-use" className="footer-link">
              Terms of Use
            </Link>
            <Link to="/privacy-policy" className="footer-link">
              Privacy Policy
            </Link>
          </Box>
          
          <Box className='footer-social-media'>
            <Link to="https://www.instagram.com/nuun_app/?hl=en" className="footer-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Link to="https://www.tiktok.com/@nuun_app" className="footer-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTiktok} />
            </Link>
            <Link to="https://x.com/nuunevents?s=11&t=sEs9kJKYNb_xOykHHgltrg" className="footer-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faXTwitter}/> 
            </Link>
            <Link to="https://www.linkedin.com/company/105348024/admin/feed/following/" className="footer-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </Link>
          </Box>
        </Box>
      </Box>
    </footer>
  );
}

export default Footer;

import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ServicesCards() {
  const navigate = useNavigate();

  const cards = [
    {
      title: 'ARTISTS',
      description: 'GROW YOUR FAN BASE',
      path: '/artists'
    },
    {
      title: 'ORGANIZER',
      description: 'MANAGE SUCCESSFUL EVENTS',
      path: '/organizer'
    },
    {
      title: 'VENUE',
      description: 'SHOWCASE YOUR VENUE',
      path: '/venue'
    }
  ];

  return (
    <Box
      sx={{
        minHeight: '50vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px'
      }}
    >
      <Grid container spacing={4} justifyContent="center" maxWidth="1200px">
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              onClick={() => navigate(card.path)}
              sx={{
                height: '200px',
                cursor: 'pointer',
                transition: 'transform 0.2s ease-in-out',
                backgroundColor: 'var(--primary-color)',
                color: 'var(--secondary-color)',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  align="center"
                  fontFamily="passion one"
                  sx={{ marginBottom: 2 }}
                >
                  {card.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  align="center"
                >
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ServicesCards;

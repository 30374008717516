import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import VideoFile from './assets/NUUNAPP.mp4';


function HomePage() {
  return (
    <div style={{
      marginBottom: '100px'
    }}> 
  
        <div className='homepage-container'>
          <div className='text-container'>
            <h1 className='main-title'> EXPERIENCE MORE<br />
              OF THE EVENTS<br />
              YOU LOVE
            </h1>
            
            <button 
              onClick={() => {
                document.getElementById('download-section').scrollIntoView({ 
                  behavior: 'smooth'
                });
              }}
              className="get-app-button"
            >
              GET THE APP
            </button>
          </div>
          
          <div className='video-container'>
            <video 
              autoPlay
              loop
              muted
              style={{
                width: '89%',
                height: 'auto',
              }}
            >
              <source src={VideoFile} type="video/mp4" /> 
            </video>
          </div>
        </div>
        
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 'calc(-50vw + 50%)',
        marginRight: 'calc(-50vw + 50%)',
        marginBottom: '60px',
        padding: '40px 20px'
      }}>
        <div style={{
          maxWidth: '900px',
          textAlign: 'center',
          position: 'relative'
        }}>

          <h3 style={{
            fontFamily: 'Passion One, cursive',
            fontSize: '2.8rem',
            marginBottom: '30px',
            color: 'var(--secondary-color)',
            textTransform: 'uppercase',
            letterSpacing: '2px'
          }}>
            Welcome to NUUN
          </h3>
          
          <p style={{
            fontSize: '1.5rem',
            lineHeight: '1.5',
            color: 'var(--secondary-color)',
            maxWidth: '800px',
            position: 'relative',
            marginBottom: '5rem',
            padding: '0 0 5rem 0',
            display: 'block'
          }}>
            Your ultimate destination for Middle Eastern and North African events. Discover, connect, and experience 
            the vibrant culture through our curated selection of events. Whether you're looking 
            for concerts, cultural festivals, or comedy shows, NUUN brings them all to 
            your fingertips. 
          </p>
        </div>
      </div>
      <div style={{
         backgroundColor: 'var(--secondary-color)',
         width: '100vw',
         marginLeft: 'calc(-50vw + 50%)',
         marginRight: 'calc(-50vw + 50%)',
         paddingTop: '20px',
     
      }}>
      <div className='homepage-container'>
      <div className='video-container'>
        <img 
          src={require('./assets/search.gif')} 
          alt="Search demonstration"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      </div>

      <div className='text-container'>
        <h4 style={{
          fontFamily: 'Passion One, cursive',
          fontSize: '2rem',
          lineHeight: '1.6',
          color: 'var(--whiteText-color)'
        }}> DISCOVER MORE</h4>
          <h4 style={{
          fontFamily: 'Passion One, cursive',
          fontSize: '2rem',
          lineHeight: '1.6',
          color: 'var(--whiteText-color)'
        }}> STAY IN THE LOOP</h4>
         <h4 style={{
          fontFamily: 'Passion One, cursive',
          fontSize: '2rem',
          lineHeight: '1.6',
          color: 'var(--whiteText-color)'
        }}>CATCH THE HOTTEST SHOWS</h4>
    
        <div id="download-section" className='download-buttons' style={{
            display: 'flex',
            gap: '15px',
            marginTop: '20px',
            paddingBottom: '100px'
          }}>
            <a 
              href="https://apps.apple.com/us/app/nuun-live-events/id6736663966?itscg=30200&itsct=apps_box_link&mttnsubad=6736663966" 
              target="_blank" 
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <button className='download-button'>
                <AppleIcon style={{ fontSize: '24px' }} />
                iOS
              </button>
            </a>
            <a 
              href="https://play.google.com/store/apps?hl=en_US" 
              target="_blank" 
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <button className='download-button'>
                <AndroidIcon style={{ fontSize: '24px' }} />
                 COMING SOON
              </button>
            </a>
          </div>
      </div>
      </div>
      </div>
         
    </div>
  );
}

export default HomePage;

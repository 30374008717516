import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function VenueService() {
  return (
    <Container 
      component="main" 
      maxWidth="lg"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '100px',
        paddingBottom: '100px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
        }}
      >
        <Typography
          component="h1"
          variant="h2"
          fontFamily={"passion one"}
          fontWeight={100}
          sx={{
            color: "var(--secondary-color)",
            textAlign: 'center',
            marginBottom: '2rem',
           
          }}
        >
          VENUES ON NUUN
        </Typography>

        <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
          <Typography 
            variant="body1" 
            paragraph
            sx={{ 
              fontSize: '1.1rem',
              lineHeight: 1.8,
              marginBottom: '1.5rem',
            }}
          >
            Showcase your venue and connect with top artists and organizers in the Middle East. NUUN helps you manage your space, attract the right events, and maximize your venue's potential.
          </Typography>

          <Typography variant="h5" fontFamily={"passion one"} gutterBottom>
            Key Features for Venues:
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Venue Profile and Showcase
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                Create a stunning digital presence for your venue. Highlight your space's 
                unique features, capacity, amenities, and showcase past events to attract 
                top artists and organizers.
              </Typography>
            </div>

            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Calendar Management
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                Efficiently manage your venue's schedule with our intuitive calendar system. 
                Track bookings, handle multiple events, and coordinate with organizers 
                seamlessly.
              </Typography>
            </div>

            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Booking and Availability Tools
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                Streamline your booking process with automated availability tracking and 
                request management. Make it easy for organizers to check dates and submit 
                booking inquiries.
              </Typography>
            </div>

            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Venue Analytics and Insights
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                Access detailed analytics about your venue's performance. Track event 
                success rates, attendance patterns, and gather valuable data to optimize 
                your venue's offerings.
              </Typography>
            </div>

            <div>
              <Typography 
                variant="h6" 
                fontFamily={"passion one"} 
                sx={{ color: "var(--secondary-color)", marginBottom: '1rem' }}
              >
                Direct Communication with Organizers
              </Typography>
              <Typography paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                Stay connected with event organizers through our integrated messaging system. 
                Discuss event details, negotiate terms, and maintain clear communication 
                channels throughout the planning process.
              </Typography>
            </div>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default VenueService;

import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function OrganizerService() {
  return (
    <Container 
      component="main" 
      maxWidth="lg"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '100px',
        paddingBottom: '100px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
        }}
      >
        <Typography
          component="h1"
          variant="h2"
          fontFamily={"passion one"}
          fontWeight={100}
          sx={{
            color: "var(--secondary-color)",
            textAlign: 'center',
            marginBottom: '2rem',
          }}
        >
          NUUN FOR ORGANIZERS
        </Typography>

        <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
          <Typography 
            variant="body1" 
            paragraph
            sx={{ 
              fontSize: '1.1rem',
              lineHeight: 1.8,
              marginBottom: '1.5rem',
            }}
          >
            Create and manage successful events with NUUN's comprehensive organizer tools. From planning to execution, we provide everything you need to make your events stand out in the Middle Eastern entertainment landscape.
          </Typography>

          <Typography variant="h5" fontFamily={"passion one"} gutterBottom>
            Key Features for Organizers:
          </Typography>
          <Typography paragraph>
            • Complete event management platform
            <br />
            • Ticket sales and management
            <br />
            • Attendee communication tools
            <br />
            • Real-time analytics and reporting
            <br />
            • Marketing and promotion tools
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default OrganizerService;

import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function VenueService() {
  return (
    <Container 
      component="main" 
      maxWidth="lg"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '100px',
        paddingBottom: '100px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
        }}
      >
        <Typography
          component="h1"
          variant="h2"
          fontFamily={"passion one"}
          fontWeight={100}
          sx={{
            color: "var(--secondary-color)",
            textAlign: 'center',
            marginBottom: '2rem',
           
          }}
        >
          NUUN FOR VENUES
        </Typography>

        <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
          <Typography 
            variant="body1" 
            paragraph
            sx={{ 
              fontSize: '1.1rem',
              lineHeight: 1.8,
              marginBottom: '1.5rem',
            }}
          >
            Showcase your venue and connect with top artists and organizers in the Middle East. NUUN helps you manage your space, attract the right events, and maximize your venue's potential.
          </Typography>

          <Typography variant="h5" fontFamily={"passion one"} gutterBottom>
            Key Features for Venues:
          </Typography>
          <Typography paragraph>
            • Venue profile and showcase
            <br />
            • Calendar management
            <br />
            • Booking and availability tools
            <br />
            • Venue analytics and insights
            <br />
            • Direct communication with organizers
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default VenueService;

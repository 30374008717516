import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useScrollTrigger,
  Slide,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { signOut } from "./auth";
import { useState } from "react";

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function NavBar() {
  const { userLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOut();
    handleClose();
    navigate("/");
  };

  return (
    <>
      <HideOnScroll>
        <AppBar
          position="fixed"
          elevation={0}
          sx={{
            backgroundColor: "white",
            backdropFilter: "none",
            opacity: 1,
          }}
        >
          <Toolbar>
            <Link to="/">
              <img
                alt="logo"
                src={require("../src/assets/FullnuunLogoV2.png")}
                className="navbar-logo"
              />
            </Link>
            <Box sx={{ flexGrow: 1 }} />

            {/* Desktop View */}
            <Box sx={{ display: { xs: "none", sm: "flex" } }}>
              {userLoggedIn ? (
                <>
                  <Button
                    component={Link}
                    to="/about"
                    disableRipple
                    sx={{ 
                      color: "var(--secondary-color)",
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                  >
                    About
                  </Button>
                  <Button
                    component={Link}
                    to="/event-form"
                    disableRipple
                    sx={{ 
                      color: "var(--secondary-color)",
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                  >
                    Add Event
                  </Button>
                  <Button
                    component={Link}
                    to="/events"
                    disableRipple
                    sx={{ 
                      color: "var(--secondary-color)",
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                  >
                    Events
                  </Button>
                  <Button
                    disableRipple
                    sx={{ 
                      color: "var(--secondary-color)",
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    disableRipple
                    component={Link}
                    to="/about"
                    sx={{ 
                      color: "var(--secondary-color)",
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                  >
                    About
                  </Button>
                  <Button
                    disableRipple
                    sx={{ 
                      color: "var(--secondary-color)",
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                    component={Link}
                    to="/login"
                  >
                    Login
                  </Button>
                  <Button
                    disableRipple
                    sx={{ 
                      color: "var(--secondary-color)",
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                    component={Link}
                    to="/signup"
                  >
                    Become a partner
                  </Button>
                </>
              )}
            </Box>

            {/* Mobile View */}
            <Box sx={{ display: { xs: "flex", sm: "none" } }}>
              {userLoggedIn ? (
                <>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    sx={{ color: "var(--secondary-color)" }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={handleClose}
                      component={Link}
                      to="/about"
                    >
                      About
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      component={Link}
                      to="/event-form"
                    >
                      Add Event
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      component={Link}
                      to="/events"
                    >
                      Events
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </>
              ) : (
                <>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    sx={{ color: "var(--secondary-color)" }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={handleClose}
                      component={Link}
                      to="/about"
                    >
                      About
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      component={Link}
                      to="/login"
                    >
                      Login
                    </MenuItem>
                    <MenuItem component={Link} to="/signup">
                      Become a partner
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  );
}

export default NavBar;
import MyCustomSVG from './assets/white.svg';
import VideoFile from './assets/NUUNAPP.mp4';


function HomePage() {
  return (
    <div className='homepage-container'>
      <div className='text-container'>
        <h1 className='main-title'> EXPERIENCE MORE<br />
          OF THE EVENTS<br />
          YOU LOVE
        </h1>
        <h5 className='sub-title'>The ultimate app for all <br /> Middle Eastern events</h5>
        
        <a 
          href="https://apps.apple.com/us/app/nuun-live-events/id6736663966?itscg=30200&itsct=apps_box_link&mttnsubad=6736663966" // Replace with your App Store link
          target="_blank" 
          rel="noopener noreferrer"
        >
          <img 
            src={MyCustomSVG} 
            alt="Download App" 
            style={{
              width: '10rem', // Adjust size as needed
              height: 'auto',
              alignSelf: 'flex-start', // Adjust alignment as needed
            }} 
          />
        </a>
      
      </div>
      
      <div className='video-container'>
        <video 
          autoPlay
          loop
          muted
          style={{
            width: '89%', // Adjust size as needed
            height: 'auto',
          }}
        >
          <source src={VideoFile} type="video/mp4" /> 
        </video>
      </div>
      
    
    </div>
  );
}

export default HomePage;

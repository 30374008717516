import React, { useState } from "react";
import { Container, Typography, TextField, Button, Box } from "@mui/material";
import { signIn } from "./auth";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSingedIn, setIsSingedIn] = useState(false);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isSingedIn) {
      setIsSingedIn(true);
      const authorizedUser = await signIn(email, password);
      if (authorizedUser) {
        navigate("/event-form");
      }
    }
  };

  return (
    <Container 
      component="main" 
      maxWidth="xs"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '100px',
        paddingBottom: '100px',
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        
        padding: "5vh",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          fontFamily={"passion one"}
          sx={{
            color: "var(--primary-color)",
            fontSize: "2rem",
            marginBottom: '2rem',
          }}
        >
          SIGN IN
        </Typography>
        <Box 
          component="form" 
          onSubmit={handleSubmit} 
          noValidate 
          sx={{ 
            mt: 1,
            width: '100%',
          }}
        >
          <TextField
            className="TextField"
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            className="TextField"
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              fontWeight: "bold",
              fontSize: 14,
              marginTop: 4,
              marginBottom: 2,
              color: "var(--secondary-color)",
              backgroundColor: "var(--primary-color)",
              ":hover": {
                backgroundColor: "var(--primary-color)",
                color: "var(--secondary-color)",
              },
            }}
          >
            SIGN IN
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default LoginPage;
